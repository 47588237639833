@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
  
      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          -webkit-overflow-scrolling: touch;   
      }
    }
  }

  .modal {
    animation: modal-open 0.4s ease-out;
  }

  .loginAnimation {
    animation: loginAni 2.5s ease-out;
  }


  .logoutAnimation {
    animation: logoutAni 1.5s ease-out;
  }

  .openDashboard {
    animation: comeInDash 1.5s ease-out;
  }

  .closeDashboard {
    animation: leaveDash 3.5s ease-out;
  }

::-webkit-calendar-picker-indicator {
  filter: invert(1)
          brightness(78%)
          sepia(100%)
          saturate(10000%)
          hue-rotate(900deg);
  cursor: pointer;
}

  @keyframes modal-open {
    0% {
      opacity: 0;
      transform: translateY(300px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes loginAni {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-700px);
    }
  }

  @keyframes logoutAni {
    0% {
      transform: translateX(-700px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes comeInDash {
    0% {
      transform: translateX(6000px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes leaveDash {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(5000px);
    }
  }

  @font-face {
    font-family: 'yantramanav-black';
    src: url('./assets/fonts/yantramanav-black.otf');
  }
  
  @font-face {
    font-family: 'yantramanav-thin';
    src: url('./assets/fonts/yantramanav-thin.otf');
  }
  
  @font-face {
    font-family: 'yantramanav-light';
    src: url('./assets/fonts/yantramanav-light.otf');
  }
  
  @font-face {
    font-family: 'yantramanav-regular';
    src: url('./assets/fonts/yantramanav-regular.otf');
  }
  
  @font-face {
    font-family: 'yantramanav-medium';
    src: url('./assets/fonts/yantramanav-medium.otf');
  }
  
  @font-face {
    font-family: 'yantramanav-bold';
    src: url('./assets/fonts/yantramanav-bold.otf');
  }
  
.gm-style iframe + div { border:none!important; } /* Used for google maps, to not show blue border on focus*/