.dynamic-height-component {
  display: flex;
  flex-direction: column;
  min-height: 70px;
}

.dynamic-height-component::before {
  content: '';
  flex-grow: 1;
  height: 0;
  min-height: 0;
}

.dynamic-height-component::after {
  content: '';
  height: 100%;
  margin-top: -70px;
}