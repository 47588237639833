.singleDataClass {
  max-height: calc(100vh - 6rem); /* 6rem is equivalent to 24px, you can adjust it based on your needs */
  overflow-y: auto;
}

.listcardHoverTranslate {
  transition: transform 0.2s linear; /* Smooth transition */

}
.listcardHoverTranslate:hover {
  transform: translateY(-2px); /* Move up by 2 pixels on hover */
}